.Editor {
  height: 100vh;
  width: 100%;
  border-left: 2px solid #000;
  /* padding: 14px; */
  display: grid;
  grid-template-rows: auto auto 1fr;
}

.editor-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
}

.editor-title * {
  margin: auto;
}

.input-title {
  font-size: 48px;
  font-weight: 600;
  outline: 0;
  border: 0;
  width: 100%;
}

.editor-edit-btns {
  padding: 10px 18px;
  width: 100%;
  overflow-x: auto;
  height: fit-content;
  white-space: nowrap;
}

.editor {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.DraftEditor-root {
  font-size: 24px;
  padding: 0 14px;
  height: 100% !important;
  max-height: 100% !important;
}

blockquote {
  margin: 0;
  padding: 5px 20px;
  border-left: 2px solid #000;
}

.public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  border-radius: 8px;
}

.public-DraftStyleDefault-pre pre {
  margin: 0;
  padding: 7px 0;
}

.public-DraftStyleDefault-pre pre * {
  font-family: monospace !important;
}

@media (max-width: 576px) {
  .DraftEditor-root {
    font-size: 18px;
  }
  .input-title {
    font-size: 2em;
  }
}
